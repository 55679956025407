$(document).ready(function () {
  // home banner parallex
  var controller = new ScrollMagic.Controller();
  var tween = new TimelineMax().add([
    TweenMax.fromTo(
      "#home-banner-background",
      1,
      { scale: 1, y: 0 },
      { y: 200, ease: Linear.easeNone }
    ),
  ]);

  var width = $(window).width();
  var height = $(window).height();
  var duration = 0;
  if (height < 800) {
    duration = 800;
  } else {
    duration = height;
  }

  var scene = new ScrollMagic.Scene({
    triggerElement: "#litdane-home",
    duration: duration,
    triggerHook: 0,
  })
    .setTween(tween)
    // .addIndicators() // add indicators (requires plugin)
    .addTo(controller);

  $(window).bind("resize", function (e) {
    if ($(this).width() != width) {
      if (window.RT) clearTimeout(window.RT);
      window.RT = setTimeout(function () {
        height = $(window).height();
        if (height < 800) {
          duration = 800;
        } else {
          duration = height;
        }
        scene = scene.destroy(true);
        // build scene
        scene = new ScrollMagic.Scene({
          triggerElement: "#litdane-home",
          duration: duration,
          triggerHook: 0,
        })
          .setTween(tween)
          // .addIndicators() // add indicators (requires plugin)
          .addTo(controller);
        // this.location.reload(false);
      }, 20);
    }

    if ($(this).height() != height) {
      if (window.RT) clearTimeout(window.RT);
      window.RT = setTimeout(function () {
        // fixed showcase filter
        $("#showcase-filter").pushpin({
          top: $("#project-list-wrap").offset().top,
        });
      }, 20);
    }
  });

  // Music Visualization
  var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  var audioElement = document.getElementById("audioElement");
  var audioSrc = audioCtx.createMediaElementSource(audioElement);
  var analyser = audioCtx.createAnalyser();

  // Bind our analyser to the media element source.
  audioSrc.connect(analyser);
  audioSrc.connect(audioCtx.destination);

  //var frequencyData = new Uint8Array(analyser.frequencyBinCount);
  var frequencyData = new Uint8Array(256);

  var svgHeight = "360";
  var svgWidth = "360";

  function createSvg(parent, height, width) {
    return d3
      .select(parent)
      .append("svg")
      .attr("height", height)
      .attr("width", width);
  }

  var svg = createSvg("#music-viz-container", svgHeight, svgWidth);

  // Create our initial D3 chart.
  svg
    .selectAll("circle")
    .data(frequencyData)
    .enter()
    .append("circle")
    .attr("cy", function (d, i) {
      return Math.sin(((360 / 256) * i * Math.PI) / 180) * 150 + 180;
    })
    .attr("cx", function (d, i) {
      return Math.cos(((360 / 256) * i * Math.PI) / 180) * 150 + 180;
    });

  var requestID;
  var reopen = false;
  // Continuously loop and update chart with frequency data.
  function renderChart() {
    // Copy frequency data to frequencyData array.
    analyser.getByteFrequencyData(frequencyData);
    // console.log(frequencyData);

    // Update d3 chart with new data.
    svg
      .selectAll("circle")
      .data(frequencyData)
      .attr("r", function (d) {
        return d / 10 + 4.5;
      })
      .attr("fill", function (d, i) {
        r = 255 - d;
        g = 128;
        b = i - 1;
        a = d / 500;
        return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
      });

    requestID = requestAnimationFrame(renderChart);
  }
  function closeChart() {
    cancelAnimationFrame(requestID);
    svg
      .selectAll("circle")
      .transition()
      .attr("r", function (d) {
        return 0;
      });
  }
  function resumeChart() {
    reopen = false;
    svg
      .selectAll("circle")
      .data(frequencyData)
      .transition()
      .attr("r", function (d) {
        return d / 8;
      })
      .attr("fill", function (d) {
        return "rgba(" + (255 - d) + ", 25, " + d + ", " + d / 500 + ")";
      });
  }

  document.getElementById("audioPlay").onclick = function () {
    if (reopen) {
      setTimeout(function () {
        resumeChart();
      }, 250);
      $("#avatar1").fadeOut();
      setTimeout(function () {
        $("#avatar2").fadeIn();
      }, 150);
    } else {
      audioCtx.resume();
      $("#avatar1").fadeOut("slow");
      setTimeout(function () {
        $("#avatar2").fadeIn("slow");
      }, 500);
    }
    setTimeout(function () {
      document.getElementById("audioElement").play();
      requestID = requestAnimationFrame(renderChart);
    }, 250);
  };
  document.getElementById("audioPause").onclick = function () {
    document.getElementById("audioElement").pause();
    closeChart();
    reopen = true;

    $("#avatar2").fadeOut("slow");
    setTimeout(function () {
      $("#avatar1").fadeIn("slow");
    }, 500);
  };
  document.getElementById("audioElement").onended = function () {
    $("#avatar2").fadeOut("slow");
    setTimeout(function () {
      $("#avatar1").fadeIn("slow");
    }, 500);
  };

  // the "href" attribute of .modal-trigger must specify the modal ID that wants to be triggered
  $(".modal-trigger").leanModal({
    dismissible: false, // Modal can be dismissed by clicking outside of the modal
    opacity: 0.5, // Opacity of modal background
    in_duration: 300, // Transition in duration
    out_duration: 200, // Transition out duration
    starting_top: "4%", // Starting top style attribute
    ending_top: "10%", // Ending top style attribute
    ready: function () {}, // Callback for Modal open
    complete: function () {}, // Callback for Modal close
  });
  $(document).on("click", "#openFact", function () {
    $("#factsModal").openModal({
      dismissible: false, // Modal can be dismissed by clicking outside of the modal
      opacity: 0.5, // Opacity of modal background
      in_duration: 300, // Transition in duration
      out_duration: 200, // Transition out duration
      starting_top: "4%", // Starting top style attribute
      ending_top: "10%", // Ending top style attribute
      ready: function () {}, // Callback for Modal open
      complete: function () {}, // Callback for Modal close
    });
  });
  // $('#closeFact').click(function() {
  // $('#factsModal').closeModal();
  // })
  $(document).on("click", "#closeFact", function () {
    $("#factsModal").closeModal();
  });
});

var app = angular.module("mazeLock", ["ngAnimate"]);

app.controller("mazeLockCtrl", function ($scope, $sce) {
  var initd = ($scope.dimension = 3);
  var initk = ($scope.keychain = 3);

  var createMaze = function (d) {
    var maze = [];
    var line = [];
    for (i = 0; i < d; i++) {
      line[i] = i + 1;
    }
    for (i = 0; i < d; i++) {
      maze[i] = line;
    }
    $scope.maze = maze;
  };
  $scope.resetMaze = function () {
    $scope.addedKeyElement = 0;
    keyArray = [];
    var allElements = document.getElementsByClassName("maze-element");
    for (i = 0; i < allElements.length; i++) {
      allElements[i].classList.remove("mazeElementActive");
    }
    // allElements.classList.remove("light");
    $scope.mazeElementEnabled = "mazeElementEnabled";
    $scope.bingo = false;
    $scope.falseKey = false;
  };

  $scope.updateMaze = function (n) {
    if ($scope.numValid != "disabled") {
      $scope.resetMaze();
      createMaze(n);
    }
  };

  $scope.validateDimension = function () {
    var d = $scope.dimension;
    var k = $scope.keychain;
    if (d > 2 && d < 10 && k > 2 && k < 10) {
      $scope.numValid = "";
    } else {
      $scope.numValid = "disabled";
    }
  };

  $scope.addedKeyElement = 0;
  var keyArray = [];
  $scope.addKeyElement = function (event) {
    /* get current clicked element ID */
    var id = event.currentTarget.id;
    /* get only the numeric part of ID */
    var kid = id.match(/\d+/)[0];
    /* check if element already selected
			if yes, unselect it */
    if ($(event.target).hasClass("mazeElementActive")) {
      var i = $scope.addedKeyElement--;
      $(event.target).removeClass("mazeElementActive");
      /* set all element as clickable */
      $scope.mazeElementEnabled = "mazeElementEnabled";
      /* since key length doesn't match, hide reveal button */
      $scope.bingo = false;
      $scope.falseKey = false;
      /* remove the unselected element from keychain */
      removeA(keyArray, kid);
    } else {
      var i = $scope.addedKeyElement++;
      if ($scope.addedKeyElement >= $scope.keychain) {
        /* check if user is exceding the maximum key length */
        if ($scope.addedKeyElement == $scope.keychain) {
          /* add the last selected element to keychain */
          keyArray[i] = kid;
          $(event.target).addClass("mazeElementActive");
        }
        /* prevent user from adding more element to keychain */
        $scope.mazeElementEnabled = "mazeElementDisabled";
        $scope.addedKeyElement = $scope.keychain;
        /* check if has a match key */
        tryKey = keyArray.sort();
        tryKey = tryKey.join("");
        findMatch(tryKey);
      } else {
        keyArray[i] = kid;
        $(event.target).addClass("mazeElementActive");
      }
    }
  };

  $scope.resetMaze();
  createMaze(initd);

  var findMatch = function ($try) {
    if ($try in $scope.facts) {
      $scope.bingo = true;
      $scope.falseKey = false;
      $scope.fact = $sce.trustAsHtml($scope.facts[$try]);
    } else {
      $scope.bingo = false;
      $scope.falseKey = true;
    }
  };

  $scope.facts = {
    111213: `
    <p>I've been to about 20 US National Parks.</p>
    `,
    111222: `
    <p>I've been to Washington (Seattle), Maine (Portland), Florida (Keywest), and California (San Diego). Not exactly the four corners of the contiguous US, but almost...</p>
    `,
    122122: `
    <p>I know how to make croissants from scratch 🥐.</p>
    `,
    132223: `
    <p>Most listened song recently:</p>
    <p>Ingrid Michaelson & ZAYN – To Begin Again</p>
    `,
    212232: `
    <p>My first meal in the US was ... in a Tim Hortons.</p>
    `,
    122233: `
    <p>I've been cutting my own hair since 2013.</p>
    `,
    222331: `
    <p>I used to be a Sony fanboy.</p>
    `,
    132231: `
    <p>I started learning Irish dance when I was 26.</p>
    `,
    112233: `
    <p>I love pineapple on pizza.</p>
    `,
    132233: `
    <p>I enjoyed watching Cougar Town a lot.</p>
    `,
    212223: `
    <p>The weirdest food I've had so far are grasshopper tacos and alligator cheesecake.</p>
    `,
    112332: `
    <p>My main physical activity is ... Just Dance (by Ubisoft, not Gaga).</p>
    `,
    122123: `
    <p>In 2019, I packed all my things in my car and drove from Boston to Toronto.</p>
    `,
    223133: `
    <p>If I could choose another profession, I would like to be a chef.</p>
    `,
    212233: `
    <p>Stranger Things is a OK...</p>
    `,
    122332: `
    <p>I'm trying to learn French. Slow progress...</p>
    `,
    122333: `
    <p>I've seen enough snow in my life. (I keep saying that)</p>
    `,
    111331: `
    <p>I enjoy cooking but not doing the dishes</p>
    `,
    123133: `
    <p>Robot vacuum and smart curtains changed my life.</p>
    `,
    122133: `
    <p>Occasionally wondering if technology has changed the world for the better.</p>
    `,
    132333: `
    <p>I think P!nk is criminally underrated.</p>
    `,
    223233: `
    <p>Google Assistant makes me question my speaking ability all the time.</p>
    `,
    313233: `
    <p>Am I in the darkest timeline?</p>
    `,
    122232: `
    <p>Yo, how many times have you tried so far?</p>
    `,
    112131: `
    <p>Fan of 99% Invisible (if you know what it is, you beautiful nerd).</p>
    `,
    212331: `
    <p>I drink on average 10 coffees per year.</p>
    `,
    123233: `
    <p>Nothing much, just wanna say thank you for stopping by and clicking around :)</p>
    `,
  };
});

/* remove element from string based on value */
function removeA(arr) {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}
